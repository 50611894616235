import { Outlet, NavLink, Link } from "react-router-dom";

// import github from "../../assets/github.svg";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <Outlet />
        </div>
    );
};

export default Layout;
